import { getTeamRef, getUserById, getUserRef } from '@/api/firestores';
import { COACH_GIGI_ID, COACH_JULIA_ID, COACH_SEBASTIAN_ID, STRIPE_PRODUCTS } from '@/config';
import type { AppDispatch, RootState } from '@/store';
import { TCoache } from '@/types';
import { db } from '@/utils/firebase/firebaseInit';
import { currentTimeInZone } from '@/utils/format/datetime';
import { collection, getDoc, getDocs, query, where } from '@firebase/firestore';
import { ClientUser, ICoach } from '@fitmate-coach/fitmate-types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';

const initialState = {
  loading: true,
  coaches: [] as TCoache[],
  selected: null as TCoache | null,
  firstTimeMessageWarning: false,
};

export const sendAsSlice = createSlice({
  name: 'sendAs',
  initialState,
  reducers: {
    selectSender: (state, action: PayloadAction<TCoache>) => {
      state.selected = action.payload;
    },
    resetFirstTimeMessageWarning: (state) => {
      state.firstTimeMessageWarning = false;
    },
    resetSendAs: (state) => {
      state.selected = null;
      state.coaches = [];
      state.firstTimeMessageWarning = false;
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendasInit.pending, (state) => {
        state.loading = true;
        state.firstTimeMessageWarning = false;
        state.selected = null;
        state.coaches = [];
      })
      .addCase(sendasInit.fulfilled, (state, action) => {
        state.firstTimeMessageWarning = false;
        if (!action.payload) {
          state.loading = false;
          return;
        }
        state.coaches = action.payload as any;
        state.selected = state.coaches[0];
        state.loading = false;
      })
      .addCase(checkFirstTimeMessageWarning.fulfilled, (state, action) => {
        state.firstTimeMessageWarning = action.payload === 0;
      });
  },
});

export const { selectSender, resetFirstTimeMessageWarning, resetSendAs } = sendAsSlice.actions;
export default sendAsSlice.reducer;

const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const sendasInit = createAppAsyncThunk<any[] | undefined, { clientUser: string }>(
  'sendas/init',
  async (arg, APIThunk) => {
    const clientUser = await getDoc(getUserRef(arg.clientUser));
    const teamId = (clientUser.data() as ClientUser)?.coachAssignment?.team;
    if (!teamId) return undefined;
    const team = await getDoc(getTeamRef(teamId));
    if (!team.exists()) return undefined;
    const userProductId = (clientUser.data() as ClientUser)?.billingData?.productId;

    const standardCoaches = [
      { ...team.data().mainCoach, main: true },
      ...team.data().supportiveCoaches.map((coach) => ({ ...coach, main: false })),
    ];

    if (!userProductId) return standardCoaches;

    const timezone = (clientUser.data() as ClientUser)?.timezone;

    if (userProductId && STRIPE_PRODUCTS.includes(userProductId) && timezone) {
      const getState = APIThunk.getState;
      const { coachAuth } = getState();
      if (coachAuth.coachData?.id === team.data()?.mainCoach.id) {
        return [
          { ...team.data().mainCoach, main: true },
          ...team.data().supportiveCoaches.map((coach) => ({ ...coach, main: false })),
        ];
      }

      const nowInUserTimezone = currentTimeInZone(timezone);
      const weekDays = [1, 2, 3, 4, 5];
      const today = Number(format(nowInUserTimezone, 'i'));
      if (
        weekDays.includes(today) &&
        nowInUserTimezone.getHours() >= 0 &&
        nowInUserTimezone.getHours() < 6
      ) {
        // 12am-> 5.59am - Gigi
        const gigi = await getUserById(COACH_GIGI_ID);
        return [
          {
            main: false,
            id: COACH_GIGI_ID,
            firstName: gigi.firstName,
            lastName: gigi.lastName,
            avatarUrl: gigi.avatarUrl,
          } as ICoach,
          ...standardCoaches,
        ];
      }

      if (
        weekDays.includes(today) &&
        nowInUserTimezone.getHours() >= 6 &&
        nowInUserTimezone.getHours() < 18
      ) {
        // 6am->5.59pm -Julia
        const julia = await getUserById(COACH_JULIA_ID);
        return [
          {
            main: false,
            id: COACH_JULIA_ID,
            firstName: julia.firstName,
            lastName: julia.lastName,
            avatarUrl: julia.avatarUrl,
          } as ICoach,
          ...standardCoaches,
        ];
      }

      if (weekDays.includes(today) && nowInUserTimezone.getHours() >= 18) {
        // 6pm -11.59pm Gigi
        const gigi = await getUserById(COACH_GIGI_ID);
        return [
          {
            main: false,
            id: COACH_GIGI_ID,
            firstName: gigi.firstName,
            lastName: gigi.lastName,
            avatarUrl: gigi.avatarUrl,
          } as ICoach,
          ...standardCoaches,
        ];
      }

      if (!weekDays.includes(today)) {
        // Sat and Sun
        // Default to Sebastian all the time
        const seb = await getUserById(COACH_SEBASTIAN_ID);
        return [
          {
            main: false,
            id: COACH_SEBASTIAN_ID,
            firstName: seb.firstName,
            lastName: seb.lastName,
            avatarUrl: seb.avatarUrl,
          } as ICoach,
          ...standardCoaches,
        ];
      }

      return undefined;
    }
    return standardCoaches;
  },
);

export const checkFirstTimeMessageWarning = createAsyncThunk(
  'sendas/checkFirstTimeMessageWarning',
  async (args: { userId: string; userIdFrom: string }) => {
    try {
      const ref = collection(db, 'messages');
      const q = query(
        ref,
        where('userId', '==', args.userId),
        where('virtualUserIdFrom', '==', args.userIdFrom),
      );
      const docSnap = await getDocs(q);

      return docSnap.size;
    } catch (e) {
      console.log('error', e);
    }
  },
);
