import { db } from '@/utils/firebase/firebaseInit';
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
  QuerySnapshot,
  where,
} from '@firebase/firestore';
import {
  ClientUser,
  CoachUser,
  EUserRoles,
  EUserStatuses,
  PartnerReferralUser,
  TFireBaseUser,
} from '@fitmate-coach/fitmate-types';
import { orderBy } from 'firebase/firestore';

export const getUserRef = (id: string) => doc(db, 'users', id) as DocumentReference<TFireBaseUser>;

export const getUserCollectionRef = () =>
  collection(db, 'users') as CollectionReference<TFireBaseUser>;

type AnyUser = ClientUser | CoachUser | PartnerReferralUser;
// TODO: Fix types
export async function getByRole(
  role: EUserRoles,
  extraQueries: QueryConstraint[] = [],
): Promise<QuerySnapshot<AnyUser>> {
  const q = query(collection(db, 'users'), where('role', '==', role), ...extraQueries);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return getDocs(q);
}

export const getSavvySignup = async () => {
  const q = query(
    collection(db, 'users'),
    where('role', '==', EUserRoles.USER),
    where('billingData.status', '==', EUserStatuses.TRIALIST),
    orderBy('createdAt', 'desc'),
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as ClientUser[];
};

export const getAppDownloaders = async () => {
  const q = query(
    collection(db, 'users'),
    where('role', '==', EUserRoles.USER),
    where('billingData.status', '==', EUserStatuses.UNPAID),
    orderBy('createdAt', 'desc'),
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as ClientUser[];
};

export const getUserById = async (id: string) => {
  const userRef = getUserRef(id);
  const userDoc = await getDoc(userRef);
  return userDoc.data() as AnyUser;
};
