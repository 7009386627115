import { CardContent } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormRoot, StyledInputLabel } from '../styles';
import { ITeamForm } from '@/types';
import { PersonSearch } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { toast } from 'sonner';
import { getVoices } from '@/api/elevenlabs';
import { useState } from 'react';

const TeamForm = ({
  mainCoach,
  setMainCoach,
  coaches,
  supportiveCoaches,
  setSupportiveCoaches,
  filteredCoaches,
  slackChannel,
  setSlackChannel,
  presentation,
  setPresentation,
  book10MinCall,
  setBook10MinCall,
  book20MinCall,
  setBook20MinCall,
  elevenLabsApiKey,
  setElevenLabsApiKey,
  elevenLabsVoice,
  setElevenLabsVoice,
  videoPresentationUrl,
  setVideoPresentationUrl,
  actionSpecificGdriveUrl,
  setActionSpecificGdriveUrl,
  monthly20MinCall,
  setMonthly20MinCall,
}: ITeamForm) => {
  const [coachVoices, setCouchVoices] = useState([]);
  const elevenlabsLookup = async () => {
    if (!elevenLabsApiKey) return;
    const voices = await getVoices(elevenLabsApiKey);
    setCouchVoices(voices);
  };

  const handleElevenlabsVoiceLookup = () => {
    toast.promise(elevenlabsLookup, {
      loading: 'Looking up voice cloned...',
      success: 'Voices found',
      error: 'Could not find voices. Please check your API key.',
    });
  };

  return (
    <CardContent>
      <FormRoot className="flex flex-col">
        <div className="flex flex-col">
          <StyledInputLabel>Choose Team main coach:</StyledInputLabel>
          <Autocomplete
            id="main-coach"
            value={mainCoach}
            onChange={(event: any, newValue: any | null) => {
              setMainCoach(newValue);
            }}
            options={coaches}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Main coach" variant="outlined" />
            )}
          />
        </div>
        {mainCoach && (
          <div>
            <StyledInputLabel>Choose Team support coaches (1 minimum):</StyledInputLabel>
            <Autocomplete
              multiple
              id="support-coaches"
              value={supportiveCoaches}
              onChange={(event: any, newValue: any | null) => {
                setSupportiveCoaches(newValue);
              }}
              options={filteredCoaches}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Supporting coach(es)" variant="outlined" />
              )}
            />
          </div>
        )}
        <div>
          <StyledInputLabel>Slack channel #:</StyledInputLabel>
          <TextField
            variant="outlined"
            value={slackChannel}
            onChange={(e) => setSlackChannel(e.target.value)}
          />
        </div>
      </FormRoot>
      <div>
        <StyledInputLabel>Presentation:</StyledInputLabel>
        <TextField
          multiline
          helperText="This is used to send a presentation of main coach during onboarding."
          variant="outlined"
          value={presentation}
          onChange={(e) => setPresentation(e.target.value)}
        />
      </div>
      <div>
        <StyledInputLabel>10 mins. Book call URL:</StyledInputLabel>
        <TextField
          fullWidth
          helperText="Give us the calendly url"
          variant="outlined"
          value={book10MinCall}
          onChange={(e) => setBook10MinCall(e.target.value)}
        />
      </div>
      <div>
        <StyledInputLabel>Paid 20 mins. Book call URL:</StyledInputLabel>
        <TextField
          fullWidth
          helperText="Give us the calendly url"
          variant="outlined"
          value={book20MinCall}
          onChange={(e) => setBook20MinCall(e.target.value)}
        />
      </div>
      <div>
        <StyledInputLabel>20 mins monthly. Book call URL:</StyledInputLabel>
        <TextField
          fullWidth
          helperText="Give us the calendly url"
          variant="outlined"
          value={monthly20MinCall}
          onChange={(e) => setMonthly20MinCall(e.target.value)}
        />
      </div>
      <div>
        <StyledInputLabel>Video presentation url:</StyledInputLabel>
        <TextField
          fullWidth
          helperText="Give us the calendly url"
          variant="outlined"
          value={videoPresentationUrl}
          onChange={(e) => setVideoPresentationUrl(e.target.value)}
        />
      </div>
      <div>
        <StyledInputLabel>GDrive specific actions url:</StyledInputLabel>
        <TextField
          fullWidth
          helperText="Give us the calendly url"
          variant="outlined"
          value={actionSpecificGdriveUrl}
          onChange={(e) => setActionSpecificGdriveUrl(e.target.value)}
        />
      </div>
      <div>
        <StyledInputLabel>Elevenlabs API Key:</StyledInputLabel>
        <TextField
          fullWidth
          variant="outlined"
          value={elevenLabsApiKey}
          onChange={(e) => setElevenLabsApiKey(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleElevenlabsVoiceLookup}>
                <PersonSearch />
              </IconButton>
            ),
          }}
        />
      </div>
      {coachVoices.length > 0 && (
        <div>
          <StyledInputLabel>Voice:</StyledInputLabel>
          <Autocomplete
            id="elevenlabs-voice"
            value={elevenLabsVoice}
            onChange={(event: any, newValue: any | null) => {
              setElevenLabsVoice(newValue);
            }}
            options={coachVoices}
            getOptionLabel={(option: any) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Voice" variant="outlined" />}
          />
        </div>
      )}
    </CardContent>
  );
};

export default TeamForm;
