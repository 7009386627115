import DateFnsAdapter from '@date-io/date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { sub, add } from 'date-fns';

const dateFns = new DateFnsAdapter();

export const checkIsToday = (value: Date) => {
  const date = new Date(value);
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const checkIsYesterday = (value: Date) => {
  const date = new Date(value);
  const yesterday = new Date(Date.now() - 3600 * 24 * 1000);

  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
};

export const checkIfFeature = (value: Date | string) => new Date(value) > new Date();

export const formatTime = (value: number) => {
  const date = new Date(value);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const hoursStr = hours <= 12 ? hours : hours - 12;
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;

  return hoursStr + ':' + minutesStr + ' ' + ampm;
};

export const formatDateBrief = (value: number, locale?: string) => {
  const date = new Date(value);

  return date.toLocaleString(locale ?? 'en-GB', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const formatDateLastSeen = (user: any) => {
  let value = 0;
  if (typeof user.lastSeenAt === 'number') {
    value = user.lastSeenAt * 1000;
  } else if (typeof user.lastSeenAt?.seconds === 'number') {
    value = user.lastSeenAt.seconds * 1000;
  } else {
    value = user.lastSeenAtSeconds;
  }

  const date = new Date(value);
  const isToday = checkIsToday(date);

  if (isToday) {
    return `at ${formatTime(value)}`;
  }

  return date.toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
};

export const formatDateTime = (value: number) => {
  const date = new Date(value);
  const gmtDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));

  const day = String(gmtDate.getUTCDate()).padStart(2, '0');
  const month = String(gmtDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const year = gmtDate.getUTCFullYear();

  return `${day}/${month}/${year}`;
};

export const calculateAge = (value: number) => {
  const ageDt = new Date(Date.now() - value);
  const year = ageDt.getUTCFullYear();
  return Math.abs(year - 1970);
};

export const formatDateToList = (value: number): string => {
  const date = new Date(value * 1000);
  const isToday = checkIsToday(date);
  const isYesterday = checkIsYesterday(date);

  if (isToday) {
    return 'today';
  }

  if (isYesterday) {
    return 'yesterday';
  }

  return date.toLocaleString('en-US', { month: 'long', day: 'numeric' });
};

export const dateOfBirthDefault = () => {
  return new Date(1996, 10, 27).toISOString().substring(0, 10);
};

export const dateConvertFromDatePicker = (value: string) => {
  if (typeof new Date(dateFns.date(value)).getTime() !== 'number') {
    return 0;
  }
  return new Date(dateFns.date(value)).getTime();
};

export const dateConvertToDatePicker = (value: number) => {
  return new Date(value).toISOString().substring(0, 10);
};

export const getDateFromUnix = (unixTimeStamp: number) => {
  const date = new Date(unixTimeStamp * 1000);

  return date
    .toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })
    .replaceAll('/', '.');
};

export const getTimeFromUnix = (unixTimeStamp: number) => {
  const date = new Date(unixTimeStamp * 1000);

  return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' });
};

export const getNumberDays = (date: string): string | null => {
  const nowDate = new Date();
  const oldData = new Date(date);
  const oneDay = 1000 * 60 * 60 * 24;

  const numberDay = Math.round((nowDate.getTime() - oldData.getTime()) / oneDay);
  if (numberDay === 0) return 'Today';
  if (numberDay === 1) return 'Yesterday';
  return numberDay ? `${numberDay} days ago` : null;
};

export const getTodayInShortFormat = () =>
  new Date()
    .toLocaleString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric' })
    .split('/')
    .reverse()
    .join('-');

export const getTimezoneTime = (time: Date, timezone?: string) => {
  if (!timezone) return time;
  if (/^\d/.test(timezone)) return add(time.getTime(), { hours: Math.abs(parseInt(timezone)) });
  if (/^-\d/.test(timezone)) return sub(time.getTime(), { hours: Math.abs(parseInt(timezone)) });
  if (timezone.toLowerCase() === 'cdt') return sub(time.getTime(), { hours: 5 });
  try {
    return utcToZonedTime(time.getTime(), timezone);
  } catch (e) {
    console.error(`Bad timezone (${timezone})`, e);
    return time;
  }
};

export const currentTimeInZone = (tz: string) => {
  const today = new Date();
  return utcToZonedTime(today, tz);
};
