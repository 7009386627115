import { Card, CardActions, CardHeader } from '@mui/material';
import Button from '@/common/core/Button';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { createTeam, fetchCoaches } from '@/store/coaches/coachesSlice';
import { StyledDrawer, StyledDrawerPaper } from '../styles';
import { IAddTeamDrawer, ITeamSupportCoach } from '@/types';
import TeamForm from '../TeamForm';

const AddTeamDrawer = ({ open, setOpen }: IAddTeamDrawer) => {
  const dispatch = useAppDispatch();
  const coaches = useAppSelector((state) => state.coaches.coaches);

  const [mainCoach, setMainCoach] = useState<ITeamSupportCoach | undefined>();
  const [supportiveCoaches, setSupportiveCoaches] = useState([]);
  const [filteredCoaches, setFilteredCoaches] = useState<ITeamSupportCoach[]>([]);
  const [slackChannel, setSlackChannel] = useState('');
  const [presentation, setPresentation] = useState('');
  const [book10MinCall, setBook10MinCall] = useState('');
  const [book20MinCall, setBook20MinCall] = useState('');
  const [elevenLabsApiKey, setElevenLabsApiKey] = useState<string | undefined>();
  const [elevenLabsVoice, setElevenLabsVoice] = useState('');
  const [videoPresentationUrl, setVideoPresentationUrl] = useState('');
  const [actionSpecificGdriveUrl, setActionSpecificGdriveUrl] = useState('');
  const [monthly20MinCall, setMonthly20MinCall] = useState('');

  useEffect(() => {
    dispatch(fetchCoaches());
  }, []);

  useEffect(() => {
    if (coaches) {
      setFilteredCoaches(coaches as ITeamSupportCoach[]);
    }
  }, [coaches]);

  useEffect(() => {
    if (mainCoach) {
      const filtered = coaches.filter((coach) => coach.id !== mainCoach.id);
      setFilteredCoaches(filtered as ITeamSupportCoach[]);
    }
  }, [mainCoach]);

  const handleAddTeam = () => {
    if (!mainCoach) return;
    dispatch(
      createTeam({
        mainCoach,
        supportiveCoaches,
        slackChannel,
        presentation,
        book10MinCall,
        book20MinCall,
        elevenLabsApiKey,
        elevenLabsVoice,
        videoPresentationUrl,
        actionSpecificGdriveUrl,
        monthly20MinCall,
      }),
    );
    setMainCoach(undefined);
    setSupportiveCoaches([]);
    setSlackChannel('');
    setPresentation('');
    setBook10MinCall('');
    setBook20MinCall('');
    setElevenLabsApiKey(undefined);
    setElevenLabsVoice('');
    setVideoPresentationUrl('');
    setActionSpecificGdriveUrl('');
    setMonthly20MinCall('');
    setOpen(false);
  };

  return (
    <StyledDrawer anchor="right" open={open} PaperProps={{ component: StyledDrawerPaper }}>
      <Card className="min-h-screen overflow-auto">
        <CardHeader title="Create new team" />
        <TeamForm
          mainCoach={mainCoach}
          setMainCoach={setMainCoach}
          filteredCoaches={filteredCoaches}
          supportiveCoaches={supportiveCoaches}
          setSupportiveCoaches={setSupportiveCoaches}
          slackChannel={slackChannel}
          setSlackChannel={setSlackChannel}
          presentation={presentation}
          setPresentation={setPresentation}
          coaches={coaches}
          book10MinCall={book10MinCall}
          setBook10MinCall={setBook10MinCall}
          book20MinCall={book20MinCall}
          setBook20MinCall={setBook20MinCall}
          elevenLabsApiKey={elevenLabsApiKey}
          setElevenLabsApiKey={setElevenLabsApiKey}
          elevenLabsVoice={elevenLabsVoice}
          setElevenLabsVoice={setElevenLabsVoice}
          videoPresentationUrl={videoPresentationUrl}
          setVideoPresentationUrl={setVideoPresentationUrl}
          actionSpecificGdriveUrl={actionSpecificGdriveUrl}
          setActionSpecificGdriveUrl={setActionSpecificGdriveUrl}
          monthly20MinCall={monthly20MinCall}
          setMonthly20MinCall={setMonthly20MinCall}
        />
        <CardActions>
          <Button variant="contained" color="secondary" onClick={() => setOpen(!open)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddTeam}
            disabled={!mainCoach || supportiveCoaches.length < 1}
          >
            Create
          </Button>
        </CardActions>
      </Card>
    </StyledDrawer>
  );
};

export default AddTeamDrawer;
