import React, { useContext } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { Skeleton, Tab, Tabs, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Context } from '@/context/Context';
import { CurrentTime } from '@/common/core/Time';
import { useSetChatReadStatus } from '@/hooks/chat/useChatThreadUnRead';
import { formatDateLastSeen } from '@/utils/format/datetime';
import { getUserStatus } from '@/utils/data/payment';
import { getTeamRef } from '@/api/firestores';
import {
  ActiveStatus,
  BottomBlock,
  BottomIcon,
  BottomIconsBlock,
  BottomIconsPlanner,
  CancellationDate,
  ClientInfoBlock,
  ClientInfoBottomBlock,
  ClientInfoTopBlock,
  ClientNameText,
  Container,
  LastSeenDateText,
  PaymentStatus,
  ReadIcon,
  ReadIconActivated,
  ReadText,
  ReadTextActivated,
  StatusBlock,
  StyledButton,
  TabsBlock,
  TagBox,
  TagChip,
  TopBlock,
  TopLeftBlock,
  TopRightBlock,
} from './styles';
import { EChatPageType, EPaymentStatusValue, IDiabetesChip } from '@/types';
import { ClientUser, IPartnerData } from '@fitmate-coach/fitmate-types';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { initFaq } from '@/store/customers/progressFeedbackSlice';
import { toggleAssignModal } from '@/store/teams/teamSlice';
import Button from '@/common/core/Button';
import CoachAssignmentModal from '@/components/Admin/ClientsContainer/ClientModal/CoachAssignmentModal';
import { toast } from 'sonner';
import { format } from 'date-fns';

const MessageVisibilityCallToAction = ({ currentUser }: { currentUser: ClientUser }) => {
  const isMessagesUnRead = currentUser?.isMessagesUnRead;

  if (isMessagesUnRead) {
    return (
      <>
        <ReadTextActivated>Mark as Read</ReadTextActivated>
        <ReadIconActivated as={VisibilityIcon} />
      </>
    );
  }

  return (
    <>
      <ReadText>Mark as Unread</ReadText>
      <ReadIcon as={VisibilityOffIcon} />
    </>
  );
};

const partnerChipName = (partner: string) => {
  const list: any = {
    '4IX9dX8l5lS6Zz8JUNVgzl8L4Wx2': 'BGS',
    IvV2qehoKxQcu4YUoJv53xTriDe2: 'Empower',
    UoBgD7vC3cRFVd51aJWpRAQqOk12: 'Fitmate-test',
    wuL2AGcKo4OJm3HWvfc4hUXU20l2: 'Ola Big Island',
    EftFXV2XySUqEi3dNelOh59aVhl2: 'Chicago skin clinic',
    WHsRzwIVLMYw81nsRxaZhE2bOA73: 'Reveal Wellness',
  };

  return list[partner] || 'n/a';
};

const DiabetesChip = (user: any) => {
  let label;

  switch (user.diabetes) {
    case 1:
      label = 'Pre-diabetes';
      break;
    case 2:
      label = 'Type 1 diabetes';
      break;
    case 3:
      label = 'Type 2 diabetes';
      break;
  }

  if (user.diabetes === 0 || !user.diabetes) {
    return null;
  }

  return <TagChip label={label} />;
};

const MedicalClearanceChip = (partnerData: any) => {
  const { medicalClearance } = partnerData.partnerData as IPartnerData;
  let label;
  if (medicalClearance) {
    switch (medicalClearance) {
      case 'low':
        label = 'Low';
        break;
      case 'nutrition':
        label = 'Nutrition only';
        break;
      default:
        return null;
    }
  } else {
    return null;
  }

  return <TagChip label={`${label}`} />;
};

const ChatNavigation = ({
  pageType,
  handlePageType,
  isCopilotOpen,
  handleIsCopilotOpen,
}: IDiabetesChip) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { showAssignModal, teams } = useAppSelector((state) => state.team);
  const setChatReadStatus = useSetChatReadStatus();
  const openedMyPlanDrawer = useContext(Context).openedMyPlanDrawer;
  const setOpenedMyPlanDrawer = useContext(Context).setOpenedMyPlanDrawer;
  const isClientInfoOpen = useContext(Context).isClientInfoOpen;
  const setIsClientInfoOpen = useContext(Context).setIsClientInfoOpen;

  const userStatus = getUserStatus(
    customer?.billingData?.status,
    customer?.billingData?.isPaid,
    customer?.billingData?.isReferralLinkEnabled,
  );
  const haveCoachAssigned = customer?.coachAssignment?.team !== undefined;
  const handleChange = (_event: any, newValue: string) => {
    handlePageType(newValue);
  };

  const handleMarkAsRead = async () => {
    if (customer?.id) {
      await setChatReadStatus(customer.id, !!customer?.isMessagesUnRead);
    }
  };

  const getUserClasByStatus = () => {
    if (
      userStatus?.value === EPaymentStatusValue.PAID ||
      userStatus?.value === EPaymentStatusValue.TRIALING ||
      userStatus?.value === EPaymentStatusValue.TRIAL
    )
      return 'paid';
    if (userStatus?.value === EPaymentStatusValue.RETARGETING) return 'retargeting';
    if (userStatus?.value === EPaymentStatusValue.UNPAID) return 'unpaid';
    if (userStatus?.value === EPaymentStatusValue.CANCELLED) return 'cancelled';
    if (userStatus?.value === EPaymentStatusValue.PAST_DUE) return 'cancelled';
  };

  const handleBackDashboard = () => {
    toast.dismiss(); // clear all toasts on navigation
    navigate('/coach-dashboard', { replace: true });
  };

  const handleOpenFaqDrawer = () => {
    dispatch(initFaq({ message: { userId: customer?.id, body: '' } }));
  };

  const handleAssignModal = () => {
    dispatch(toggleAssignModal());
  };

  return (
    <Container>
      <TopBlock>
        <TopLeftBlock>
          <ClientInfoBlock>
            <ClientInfoTopBlock>
              {!customer && <Skeleton animation="wave" width={190} className="mt-1" />}
              <ClientNameText>
                {customer?.firstName} {customer?.lastName}
              </ClientNameText>
              {customer?.coachAssignment?.team && (
                <MainTeamCoach teamId={customer?.coachAssignment?.team} />
              )}
              {customer && (
                <TagBox>
                  {customer.partner && <TagChip label={partnerChipName(customer.partner)} />}
                  {customer.partnerData && (
                    <MedicalClearanceChip partnerData={customer.partnerData} />
                  )}
                  <DiabetesChip diabetes={customer.diabetes} />
                  {customer.glp1drugs && <TagChip label="GLP-1 meds" />}
                </TagBox>
              )}
              <StyledButton
                onClick={handleBackDashboard}
                variant="outlined"
                size="small"
                sx={{
                  color: 'grey',
                }}
              >
                Back to dashboard
              </StyledButton>
            </ClientInfoTopBlock>
            <ClientInfoBottomBlock>
              {!customer && <Skeleton animation="wave" width={340} className="mt-1" />}
              {customer && (
                <>
                  <LastSeenDateText>
                    Last seen {formatDateLastSeen(customer)} - <b>User local time: </b>
                  </LastSeenDateText>
                  <LastSeenDateText>
                    <CurrentTime timezone={customer?.timezone} format="HH:mm (E, LLLL dd)" />
                  </LastSeenDateText>
                </>
              )}
            </ClientInfoBottomBlock>
          </ClientInfoBlock>
        </TopLeftBlock>
        <TopRightBlock>
          {customer?.billingData?.cancellationDate &&
            (!customer?.billingData?.futureOffer ||
              customer?.billingData?.futureOffer !== 'yes') && (
              <StatusBlock>
                <CancellationDate>
                  Scheduled cancellation:{' '}
                  {format(customer.billingData.cancellationDate.toDate(), 'd LLL yyyy')}
                </CancellationDate>
              </StatusBlock>
            )}

          {userStatus && (
            <StatusBlock>
              <PaymentStatus paymentStatus={getUserClasByStatus()}>
                {userStatus.label}
              </PaymentStatus>
            </StatusBlock>
          )}

          {customer?.id && (
            <ActiveStatus onClick={handleMarkAsRead} onKeyDown={handleMarkAsRead}>
              <MessageVisibilityCallToAction currentUser={customer} />
            </ActiveStatus>
          )}

          {customer?.coachAssignment && !customer?.coachAssignment.team && (
            <Button onClick={handleAssignModal}>Assign main coach</Button>
          )}
        </TopRightBlock>
      </TopBlock>
      <BottomBlock>
        <TabsBlock>
          <Tabs
            value={pageType}
            onChange={handleChange}
            // classes={{ indicator: classes.tabIndicator }}
            aria-label="Switch conversation and program"
          >
            <Tab
              label="Conversation"
              value={EChatPageType.CONVERSATION}
              // classes={{ root: classes.tab, selected: classes.tabChosen }}
            />
            <Tab
              label="Program"
              value={EChatPageType.PROGRAM}
              // classes={{ root: classes.tab, selected: classes.tabChosen }}
            />
            <Tab
              label="Client info"
              value={EChatPageType.USER_PROFILE}
              // classes={{ root: classes.tab, selected: classes.tabChosen }}
            />
            <Tab
              label="Survey"
              value={EChatPageType.SURVEY}
              // classes={{ root: classes.tab, selected: classes.tabChosen }}
            />
            <Tab label="Savvy data" value={EChatPageType.SAVVY_DATA} />
            <Tab
              label="Reminders"
              value={EChatPageType.REMINDERS}
              // classes={{ root: classes.tab, selected: classes.tabChosen }}
            />
          </Tabs>
        </TabsBlock>
        <BottomIconsBlock>
          <div className="flex w-full justify-end">
            <BottomIcon as={PersonIcon} onClick={() => setIsClientInfoOpen(!isClientInfoOpen)} />
            <BottomIcon as={SchoolIcon} onClick={handleOpenFaqDrawer} />
            {haveCoachAssigned && (
              <BottomIcon
                isCopilotOpen={isCopilotOpen}
                onClick={() => handleIsCopilotOpen(!isCopilotOpen)}
                style={{ width: '1.3rem' }}
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="m22 2-2.5 1.4L17 2l1.4 2.5L17 7l2.5-1.4L22 7l-1.4-2.5zm-7.63 5.29a.9959.9959 0 0 0-1.41 0L1.29 18.96c-.39.39-.39 1.02 0 1.41l2.34 2.34c.39.39 1.02.39 1.41 0L16.7 11.05c.39-.39.39-1.02 0-1.41l-2.33-2.35zm-1.03 5.49-2.12-2.12 2.44-2.44 2.12 2.12-2.44 2.44z"></path>
              </BottomIcon>
            )}
            <BottomIconsPlanner
              isMyPlanDrawerOpen={openedMyPlanDrawer}
              as={EventAvailableIcon}
              onClick={() => setOpenedMyPlanDrawer(!openedMyPlanDrawer)}
            />
          </div>
        </BottomIconsBlock>
      </BottomBlock>

      {customer && (
        <CoachAssignmentModal
          userIdCurrent={customer.id!}
          userName={customer.firstName!}
          isOpenModal={showAssignModal}
          toggleOpenModal={handleAssignModal}
        />
      )}
    </Container>
  );
};

const MainTeamCoach = (p: { teamId: string }) => {
  const [data] = useDocumentDataOnce(getTeamRef(p.teamId));
  if (!data?.mainCoach.firstName) return null;
  return (
    <Typography
      style={{
        color: 'rgba(15, 15, 33, 0.4)',
        fontWeight: 600,
        paddingLeft: '.3rem',
        marginTop: 12,
      }}
    >
      ({data?.mainCoach.firstName}'s team)
    </Typography>
  );
};

export default ChatNavigation;
