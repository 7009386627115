// @TODO Remove the 3 times 'trial' 'active' ...
export interface IPaymentStatus {
  label: string;
  labelShort: string;
  value: 'trial' | 'active' | 'unpaid' | 'cancelled' | 'retargeting' | 'past_due' | 'trialing';
  color: string;
}

export enum EPaymentListType {
  UNPAID = 'unpaid',
  TRIAL = 'trial',
  ACTIVE_REF = 'active_ref',
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  RETARGETING = 'retargeting',
  PAST_DUE = 'past_due',
}

export interface IPaymentListItem {
  title: string;
  value: EPaymentListType;
  dataIsPaid: boolean;
}

export enum EPaymentStatusValue {
  TRIALING = 'trialing',
  TRIAL = 'trial',
  PAID = 'active',
  UNPAID = 'unpaid',
  CANCELLED = 'cancelled',
  RETARGETING = 'retargeting',
  PAST_DUE = 'past_due',
}
